<template>
  <content-container
    description="Coming soon 🙃"
    class="data-clients"
  />
</template>

<script>
import ContentContainer from '../../components/ContentContainer/ContentContainer'

export default {
  components: {
    ContentContainer,
  },
}
</script>
